.footerContainer{
    width: 100%;
    background-color: #242424;

    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 6;
    min-height: 0vh;
    flex-grow: 1;
    .footerCard{
        width: 80%;
        height: 8em;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 16px;
        .contact{
            display: flex;
            flex-direction: column;
            span{
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 8px;
                svg{
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
            }
        }
        .siteMap{
            display: flex;
            flex-direction: column;
            align-items: center;
            h2{
                font-size: 16px;
                margin-bottom: 16px;
            }
            span{
                margin-bottom: 8px;
                font-size: 16px;
                font-weight: 600;
            }
        }
        .crea{
            display: flex;
            flex-direction: column;
            align-items: center;
            span{
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 8px;
            }
        }
        .icons {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            width: 12%;
            justify-content: center;
            .iconsGroup {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                gap: 8px;
            }
        }
    }
    .copyright{
        width: 100%;
        height: 2em;
        background-color: black;
        display: flex;
        text-align: center;
        justify-content: center;
        span{
            display: flex;
            align-items:center;
            font-size: 12px;

        }
        }
    }

    @media (max-width: 1080px){
        .footerContainer{
            .footerCard{
                width: 100%;
                .contact{
                    margin-bottom: 16px;
                    span{
                        font-size: 12px;
                    }
                }
                .siteMap{
                    margin-bottom: 16px;
                    h2{
                        font-size: 12px;
                    }
                    span{
                        font-size: 12px;
                    }
                }
                .crea{
                    margin-bottom: 16px;

                }
                .icons{
                    width: 10%;
                }
            }
        }
    }
    @media (max-width: 980px){
        .footerContainer{
            .footerCard{
                .contact{
                    margin-bottom: 16px;
                    span{
                        font-size: 12px;
                    }
                }
                .icons{
                    width: 15%;
                }
            }
        }
    }
    @media (max-width: 980px){
        .footerContainer{
            margin-top: 24px;
            .footerCard{
                width: 100%;
                flex-direction: column;
                height: 100%;
                
                .contact{
                    margin-bottom: 16px;
                    align-items: center ;
                    span{
                        font-size: 12px;
                    }
                }
                .siteMap{
                    align-items: center ;
                    h2{
                        font-size: 12px;
                    }
                    span{
                        font-size: 12px;
                    }
                }
                .crea{
                    margin-bottom: 16px;
                }
                .icons{
                    width: 100%;
                }
            }
        }
    }