.containerServices {
  width: 180vh;
  height: 82vh;
  position: relative;

  .servicesContainer {
    position: relative;
    height: 100%;
    width: 100%;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 80%;
      height: 70%;
      position: fixed;
      transition: bottom .8s ease-out;
      left: 20%;
      top: 15%;
      border-radius: 16px;
      z-index: 2; 
    
    }

    .card {
      width: 95%;
      height: 95%;
      padding: 16px;
      border-radius: 16px;
      position: relative;
      overflow: hidden;

      .textheader {
        width: 100%;
        position: relative;
      }

      .imagesContainer {
        width: 100%;
        height: 50%;
        padding: 2em;
        position: relative;
        overflow-x: hidden;
        user-select: none;
      }

      .imagesContainer:active {
        cursor: grabbing;
      }

      .images {
        display: flex;
        gap: 10px;
        width: 100%;
        bottom: 10px;
      }

      .imagesItem {
        width: 6em;
        height: 6em;
        user-select: none;
        transition: all 0.5s ease-out;
      }

      .imagesItem:hover {
        transform: scale(1.5);
      }
    }
    .item.active {
      top: 15%;
      width: 70%;
      height: 60%;
      left: 20%;
      padding: 24px;
      transition: top 0.8s ease-out;
    }

    span {
      font-size: 24px;
      color: #424040;
      border-bottom: 1px solid #3584CE;
    }

    p {
      margin-top: 16px;
      color: #424040;
      font-size: 18px;
    }
  }
}

@media (max-width: 1700px) {
  .containerServices {
    width: 100%;
    .servicesContainer {
      .item{
        position: relative;
      }
      .item.active {
        height: 90%;
        margin: 16px;
        top:0;
      }

      span {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 980px) {
  .containerServices {

    .servicesContainer {
      .card {
        
        .imagesContainer{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          margin-top: 2em;
        }
        .imagesItem{
          width: 20%;
          height: 4em;
        }
      }
      .item.active {
        left: 10%;
      }

      span {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
      
      .images {
       
        flex-wrap: wrap; 
      }
    }
  }
}
@media (max-width: 400px) {
  .containerServices {

    .servicesContainer {
     .item{
        width: 100%;
      }
     .item.active{
       left: 0;
        width: 100%;
        span{
          font-size: 24px;
        
        }
        p{
          font-size: 16px;
        }
      }
      .card {
        overflow: auto;
        .imagesContainer{
        
        }
        .imagesItem{
       
        }
        .imagesItem:hover{
          transform: scale(2.5);
        }
      }
      .item.active {
      }

      span {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
      
      .images {
       
        flex-wrap: wrap; 
      }
    }
  }
}