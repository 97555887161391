.button{
    width: 100%;
    height: 36px;
    border-radius: 16px;
    font-size: 14px;
    padding: 4px;
    font-weight: 500;
    background: #3584CE;
    color: white;
    cursor: pointer;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    transition: all 0.3s;
}
button:hover{
    background: white;
    color: black;
    border: solid 1px black;
    
}