
.contactContainer{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
   
    .contactContent{
        width: 76%;
        height: 100%;
        display: flex;
        .contactTitle{
            width: 40%;
            z-index: 4;
            height: 100%;
            padding: 16px 48px;
            text-align: center;
            h1{
                font-size: 36px;
            }
            h1:first-child{
                margin-top: 4em;
            }
            h1:nth-child(2){
                margin-bottom: 2em;
            }
            h2{
                margin-bottom: 2em;
            }
        }
        .contactText{
            width: 100%;
            padding: 16px 48px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            animation: slideInFromBottom 0.8s ease-out forwards;
            span{
                font-size: 24px;
                color: #424040;
            }
        }
    }
    .contactForm{
        width: 40%;
        z-index: 5;
        animation: slideInFromTop 0.8s ease-out forwards;
        margin-top: 6em;
        margin-left: 4em;
        height: 36em;
        border-radius: 16px;
        text-align: center;
        padding: 16px;
        padding-top: 24px;
        z-index: 4;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        h1{
            margin-bottom: 2em;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            input{
                width: 60%;
                height: 4em;
                margin-bottom: 2em;
                padding: 8px;
                outline: none;
                border: none;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            }
            textarea{
                width: 60%;
                height: 8em;
                border: none;
                margin-bottom: 2em;
                padding: 8px;
                border-radius: 8px;
                outline: none;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                resize: none;
            }
        }
    }
}
.White{
    position: absolute;
    width: 100%;
    margin-left: 0%;
    height: 22em;
    background-color: white;
    z-index: 1;
    bottom: 0;
    padding-left: 15%;
    padding-top: 12em;
    animation: slideInFromBottom 0.8s ease-out forwards;
    span{
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        svg{
            margin-right: 8px;
            width: 24px;
            height: 24px;
        }
    }
}
@keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .contactTitle h1,
.contactTitle h2 {
  animation: slideInFromRight 0.8s ease-out forwards;
}
@media (max-width: 1500px){
    .contactContainer{
        width: 100%;
        height: 100%;
        display: flex;
        
        .contactContent{
            width: 100%;
            height: 100%;
            display: flex;
            .contactTitle{
                width: 60%;
                h1:first-child{
                    margin-top: 2em;
                    font-size: 28px;
                }
                
                h1:nth-child(2){
                    margin-bottom: 1em;
                    font-size: 28px;
                }
                h2{
                    margin-bottom: 6em;
                }
            }
        }
        .contactForm{
            width: 35%;
            margin-top: 6em;
            margin-left: 0;
            form{
                input{
                    width: 80%;
                }
                textarea{
                    width: 80%;
                }
            }
        }
    }
    .White{
        padding-left: 14em;
        height: 22em;
    }
}
@media (max-width: 1080px){
    .contactContainer{
        .contactContent{
            .contactTitle{
                width: 80%;
                padding: 0;
                h1:first-child{
                    margin-top: 2em;
                    font-size: 16px;
                }
                
                h1:nth-child(2){
                    margin-bottom: 1em;
                    font-size: 16px;
                }
                h2{
                    margin-bottom: 3em;
                }
            }
        }
        .contactForm{
            width: 45%;
            margin-top: 4em;
            margin-left: 0;
            form{
                input{
                    width: 80%;
                }
                textarea{
                    width: 80%;
                }
            }
        }
    }
    .White{
        padding-left: 10em;
        height: 28em;
    }
}
@media (max-width: 980px){
    .contactContainer{
        display: flex;
        flex-direction: column;
        .contactContent{
            flex-direction: column;
        align-items: center;

            .contactTitle{
                width: 80%;
                padding: 24px;
                border-radius: 16px;
                background-color: #F6F6F6;
                .line{
                    border: 2px solid #3584CE;
                    padding: 16px;
                    border-radius: 16px;
                }
                h1:first-child{
                    margin-top: 0em;
                    font-size: 18px;
                }
                
                h1:nth-child(2){
                    margin-bottom: 1em;
                    font-size: 18px;
                }
                h2{
                    font-size: 12px;
                    margin-bottom: 1em;
                }
            }
            .contactText{
                padding: 0;
                span{
                    font-size: 16px;
                }
        }
        }
        .contactForm{
            width: 80%;
            margin: 16px 0;
            height: auto;
            form{
                h1{
                    margin-bottom: 16px;
                }
                input{
                    width: 80%;
                }
                textarea{
                    width: 80%;
                }
            }
        }
    }
    .White{
        display: none;
    }
}