.navBarContainer {
    width: 14%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    z-index: 5;
    .navBarCardItem{
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px;
      height: 100%;
      background-color: rgba(36, 36, 36, 0.5);


      border-radius: 16px;
      border: solid 2px #3584CE;
    }
    .logoCard {
      margin-top: 8%;
    }
  
    .navBarCard {

      width: 80%;
      height: 80%;
      margin-top: 50%;
      transition: height 0.5s ease;
      
      .navList {
        list-style: none;
        display: flex;
        flex-direction: column;
  
        li {
          margin: 4px 0;
          font-weight: 600;
          cursor: pointer;
          font-size: 16px;
        }
       
      }
  
      .navListItems {
        list-style: none;
        display: flex;
        flex-direction: column;
  
        h1 {
          font-size: 16px;
          font-weight: 600;
          margin: 16px 0;
          width: 100%;
          border-bottom: solid 2px;
        }
  
        li h2 {
          margin: 8px 0 0 0px;
          font-weight: 400;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  
    .menuToggle {
      display: none;
      cursor: pointer;
      .hamburger {
        width: 30px;
        height: 3px;
        background-color: white;
        margin: 5px 0;
        transition: 0.4s;
      }
    }
  }
  .buttonCard{
    width: 100%;
    margin-top: 7em;
  }
  .icons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 2em;
    svg{
      color: white;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .mailTo{
      margin-top: -4px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    svg:hover{
      transition: all 0.3s;
      color: #3584CE;
    }
  }
  .hoverEffect {
    transition: color 0.3s ease;
  }
  
  .hoverEffect:hover {
    color: #3584CE !important; 
  }
  @media screen and (max-width: 1366px) {
    .navBarContainer{
      width: 30%;
      .navBarCardItem{
        .navBarCard {
          margin-top: 10%;
        }
        
      }
      .buttonCard{
        width: 100%;
        margin-top: 2em;
      }
    }
  }
  @media screen and (max-width: 1080px) {
    .navBarContainer {
      
      .logoCard {
        margin-top: 8%;
      }
  
      .navBarCard {
        margin-top: 1em;
        animation: alternate 0.5s ScrollDown;
        .navList {
          li {
            margin: 2px 0;
          }
        }
  
        .navListItems {
          h1 {
            font-size: 16px;
            font-weight: 600;
            margin: 16px 0;
            border-bottom: solid 2px;
          }
  
          li h2 {
            margin: 8px 0 0 16px;
            font-weight: 400;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 980px) {
    .navBarContainer {
      width: 100%;
      height: 7em;
      text-align: right;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px;
      margin-bottom: 16px;
      background-color: rgba(0, 0, 0, 0.8);
      .navBarCardItem{
        border: none;
        
      }
      .logoCard {
        position: absolute;
        margin-left: 16px;
        margin-top: 0;
      }
  
      .navBarCard {
      display: none;
      .navListItems{
        width: 100%;
      }
      }
  
      .menuToggle {
        display: block;
        position: absolute;
        right: 16px;
        top: 54px;
        .hamburger {
          width: 32px;
          height: 3px;
          background-color: white;
          margin: 5px 0;
          transition: 0.4s;
        }
      }
  
      .menuToggle.open .hamburger:nth-child(1) {
        transform: rotate(50deg) translate(6px, 5px);
      }
  
      .menuToggle.open .hamburger:nth-child(2) {
        opacity: 0;
      }
  
      .menuToggle.open .hamburger:nth-child(3) {
        transform: rotate(-50deg) translate(5px, -6px);
      }
  
      .navBarCard.open {
        display: flex;
        flex-direction: column;
        align-items: end;
        padding: 16px;
        width: 30%;
        margin: 0;
        position: absolute;
        right: 0;
        overflow: hidden;
        top: 7em;
        background-color: rgba(0, 0, 0, 0.8);
        span{
          text-align: right;
        }
      }
    }
  }
  
  @media screen and (max-width: 580px) {
    .navBarContainer {
      .navBarCard.open {
        width: 60%;
      }
    }
  }
  @keyframes ScrollDown {
    from {
      height: 0;
    }
    to {
      height: 80%;
    }
  }