.containerAbout{
    width: 76%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 2;
    overflow: hidden;
}
.containerAboutItem{
    width: 100%;
}
.aboutContainer{
    width: 60%;
    height: auto;
    margin-left: 8%;
    margin-top: 5%;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 16px;
    animation: slideInFromRight 0.8s ease-out forwards;
    margin-bottom: 4em;
    .aboutCard{
        width: 100%;
        height: 100%;
        padding: 24px;
        display: flex;
        position: relative;
        .Texts{
            width: 70%;
            text-align: center;
            h1{
                font-size: 24px;
                margin-top: 16px;
                margin-bottom: 16px;
            }
            span{
                font-size: 18px;
            }
        }
        img{
            width: 240px;
            height: 240px;
            margin-top: 16px;
            margin-left: 16px;
        }
        img:last-child{
            width: 240px;
            height: 240px;
            right: 4%;
            top: 10%;
            margin-top: 16px;
            margin-left: 16px;
            position: absolute;
        }
    }
}
.sessionCard{
    width: 60%;
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-left: 30%;
    border-radius: 16px;
    display: flex;
    position: relative;
    margin-bottom: 8px;
    animation: slideInFromLeft 0.8s ease-out forwards;
    .sessionTexts{
        width: 70%;
        padding: 16px;
        text-align: center;
        h1{
            margin-top:8px;
            font-size: 24px;
            margin-bottom: 16px;
        }
        span{
            font-size: 18px;
            margin-top: 8px;

        }
    }
    .imgs{
        position: absolute;
        right: 1%;
        bottom: 5%;
    }
}
@media (max-width: 1366px){
    .containerAbout{
        .aboutContainer{
            width: 70%;
            margin-left: 15%;
            .aboutCard{
                .Texts{
                    h1{
                        font-size: 16px;
                    }
                    span{
                        font-size: 16px;
                    }
                }
                img{
                    width: 160px;
                    height: 160px;
                
                }
            }
        }
        .sessionCard{
            width: 70%;
            margin-left: 15%;
            margin-bottom: 12%;
            .sessionTexts{
                h1{
                    font-size: 16px;

                }
                span{
                    font-size: 16px;
                }
            }
        }
    }
}
@media(max-width: 1080px){
    .containerAbout{
        .aboutContainer{
            width: 70%;
            margin-left: 15%;
            .aboutCard{
                .Texts{
                    h1{
                        font-size: 16px;
                    }
                    span{
                        font-size: 16px;
                    }
                }
            }
        }
        .sessionCard{
            width: 70%;
            margin-left: 15%;
           
        }
    }

}
@media (max-width: 980px){
    .containerAbout{
        width: 100%;
        .aboutContainer{
            width: 80%;
            margin-left: 10%;
            .aboutCard{
                .Texts{
                    width: 100%;
                    h1{
                        font-size: 16px;
                    }
                }
            }
        }
        .sessionCard{
            width: 80%;
            margin-left: 10%;
        }
    }
}
@media (max-width: 768px){
    .containerAbout{
        .aboutContainer{
            width: 90%;
            margin-left: 5%;
            .aboutCard{
                display: flex;
                flex-direction: column;
                align-items: center;
                .Texts{
                    width: 100%;
                    h1{
                        font-size: 18px;
                    }
                }
                img:last-child{
                    display: none;
                }
            }
        }
        .sessionCard{
            width: 90%;
            margin-left: 5%;
            display: flex;
            align-items: center;
            flex-direction: column;
            h1{
                font-size: 18px;
            }
            span{
             font-size: 16px;   
            }
            .imgs{
                position: relative;
            }
        }
    }
}


@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }