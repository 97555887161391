.appContainer{
    display: flex;
    flex-direction: column;
    .container{
        width: 100%;
        display: flex;
        height: 100%;
    }
   
}

@media (max-width: 980px){
    .appContainer{
        .container{
            flex-direction: column;
        }
    }
}