/* ---- Spaces ---- */
$max-width: 1280px;
$max-width-onboarding: 1028px;

$spaces-xxs: 2px;
$spaces-xs: 4px;
$spaces-sm: 8px;
$spaces-md: 16px;
$spaces-lg: 24px;
$spaces-xl: 32px;
$spaces-xxl: 40px;
$spaces-gi: 56px;
$spaces-xg: 64px;

/* ---- Border Radius ---- */
$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 8px;

/* ---- Fonts ---- */
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;

/* ---- Colors ---- */
:root{
--colors-secondary-50: #e6e7e7;
--colors-secondary-100: #E9E9E9;
--colors-secondary-200: #D9D9D9;
--colors-secondary-300: #5a5c60;
--colors-secondary-400: #605D5D;
--colors-secondary-500: #090c12;
$colors-secondary-600: #080b10;
--colors-secondary-700: #06090d;
--colors-secondary-800: #05070a;
--colors-secondary-900: black;

--colors-neutral-50: #ffffff;
--colors-neutral-100: #f7f8f9;
$colors-neutral-200: #f3f4f6;
$colors-neutral-300: #eeeff2;
$colors-neutral-400: #eaecef;
$colors-neutral-500: #dee7f8;
$colors-neutral-600: #b4c1d8;
$colors-neutral-700: #adb9cf;
$colors-neutral-800: #8a97ad;
--colors-neutral-900: #4200FF;

$colors-error-50: #ffebeb;
$colors-error-100: #fdc1c1;
$colors-error-200: #fda3a3;
$colors-error-300: #fc7a7a;
$colors-error-400: #fb6060;
$colors-error-500: #fa3838;
$colors-error-600: #e43333;
$colors-error-700: #b22828;
$colors-error-800: #8a1f1f;
$colors-error-900: #691818;

$colors-font-secondary: $colors-neutral-800;

$colors-placeholder: $colors-neutral-800;
$colors-border: $colors-neutral-500;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

body{
  font-family: "jaldi", sans-serif;
}
input,
button,
textarea {

  font-family: "jaldi", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-image: url('../assets/fundo\ 3.png');
  background-size: cover;
  a{
    color: var(--colors-neutral-100);
    text-decoration: none;
  }
}